// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Head from "../components/head"
import Team from "../components/team"

import valeurs from "../images/valeurs.svg"
import respect from "../images/respect.svg"
import simplicite from "../images/simplicite.svg"
import transparence from "../images/transparence.svg"

const Gestionnaires = (props: PageProps) => (
  <Layout color="primary">
    <SEO title="Nos valeurs" />
    <Head title='Qui sommes nous ?' color="primary">
    Une jeune équipe de passionnés qui a pour principal objectif la satisfaction de ses clients
    </Head>
    <img width='50%' src={valeurs}/>
    <img width='50%' src={simplicite}/>
    <img width='50%' src={transparence}/>
    <img width='50%' src={respect}/>
    <Head title='Notre équipe' color="white">
    Un subtil mélange d’immo et de tech
    </Head>
    <Team/>
  </Layout>
)

export default Gestionnaires