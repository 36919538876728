import { Link } from "gatsby"
import React from "react"
import styles from "./head.module.css"

const head = ({title,children,color}) => {
  
  let style = styles.container

  if(color=="primary"){
    style = styles.containerPrimary
  }
  if(color=="white"){
    style = styles.containerWhite
  }

  return (
  <div className={style} >
    <h1>{title}</h1>
    <p>{children}</p>
  </div>
)}

export default head
