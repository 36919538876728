import { Link } from "gatsby"
import React from "react"
import styles from "./team.module.css"
import aurelie from "../images/aurelie-granier-ceo.png"
import thibaud from "../images/thibaud-granier-cto.png"
import LinkedIN from "../images/LinkedIN.svg"

const Team = () => (
  <div className={styles.container}>
    <div className={styles.item}>
      <img className={styles.avatar} width="91" height="91" src={aurelie} />
      <p>Aurélie - <b>CEO</b> <a href="https://fr.linkedin.com/in/aur%C3%A9lie-granier-b08472b4"><img width="24" height="24" src={LinkedIN} /></a></p>
    </div>
    <div className={styles.item}>
      <img className={styles.avatar} width="91" height="91" src={thibaud} />
      <p>Thibaud - <b>CTO</b> <a href="https://fr.linkedin.com/in/thibaud-granier-95b65651"><img width="24" height="24" src={LinkedIN} /></a></p>
    </div>
  </div>
)

export default Team
